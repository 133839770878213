module.exports = {
  background: '#F2DFDF',
  backgroundDark: '#F2DFDF',

  primary: '#000000',
  primaryLight: '#F27979',
  primaryDark: '#000000',

  secondary: '#F27979',
  secondaryLight: '#F27979',
  secondaryDark: '#F27979',
};
